import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import { Link } from "gatsby";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1>{`CZaas V4`}</h1>
    <p>{`After trying out `}<a href="https://www.gatsbyjs.org" target="_blank">{`Gatsby`}</a>{` for the first time I was hooked. I built a demo for myself using `}<a href="https://www.netlify.com/" target="_blank">{`netlify`}</a>{` CI/CD and netlifty auth. I did this within 2-3 evenings.`}</p>
    <p>{`I love the git based deployment approach that gives me branch PR reviews. I haven't played`}{`*`}{` with other services such as forms or A/B feature branch testing which looks like they're as easy to work with giving great value.`}</p>
    <h2>{`Why rebuild my site?`}</h2>
    <p>{`Unfortunately firebase has a relatively slow first response, 200 - 400ms, to responses which wasn't getting called until `}<em parentName="p">{`after`}</em>{` the react bundle would download and mount.`}</p>
    <p>{`I knew I wanted my site to be built with gatsby and CI/CD platform with netlify. I wanted to expand on my theming by creating a theme creator. I'm looking forward to expanding on this.`}</p>
    <p>{`After using styled components which I think are good for a component based framework, I found I mixed JS in my SCSS much more than I liked. Having to escape template literals and use a arrow function to get access to variables seemed more clunky than I enjoyed. I'm sure there are great patterns out there and would be willing to use them again if a better pattern approached.`}</p>
    <h2>{`Theming`}</h2>
    <p>{`A JSON based CSS theme manager is what I settled on. This allowed for easy changing values via JS and placing into the document, as well as accessibly color checks to make sure contrast ratio was good.`}</p>
    <p>{`I created a theme store to easily access every theme locally. `}<Link to="/themes" mdxType="Link">{`Check it out!`}</Link></p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "ZQlSdEZGo": "JS",
  "someRandomId613": "Some Another Name"
}
`}</code></pre>
    <p>{`and each theme would have it's own item`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-JSON"
      }}>{`{
    "ZQlSdEZGo": {
        id: "ZQlSdEZGo",
        name: "JS",
        fg: "#fff",
        bg: "#222",
        primary: "#ffe101",
        "primary-light": "#FFE734",
        "primary-dark": "#CDB500",
        "text-on-primary": "#000000",
        error: "#E10711",
        "error-text": "#ffffff",
        "border-radius": "0px",
    }
}
`}</code></pre>
    <p>{`Then I would have a function that iterates and places each `}<inlineCode parentName="p">{`key: value`}</inlineCode>{` pair as a CSS variable behind their own style tag that I inject into the documents `}<inlineCode parentName="p">{`head`}</inlineCode>{` and an optional CSS scope for theme previews.`}</p>
    <p>{`The outputted CSS looks like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-CSS"
      }}>{`:root {
    --id: ZQlSdEZGo;
    --name: JS;
    --fg: #fff;
    --bg: #222;
    --primary: #ffe101;
    --primary-light: #FFE734;
    --primary-dark: #CDB500;
    --text-on-primary": #000000;
    --error: #E10711;
    --error-text: #ffffff;
    --border-radius: 0px;
}
`}</code></pre>
    <p>{`I would recommend is scoping your variables with a unique key. I'm hoping to release my generator as it's own package as I find it extremely useful and want to use it and my components in the future.`}</p>
    <p>{`Checkout my `}<Link to="/themes/create" mdxType="Link">{`theme creator`}</Link>{`.`}</p>
    <h2>{`Content Management`}</h2>
    <p>{`I'm using `}<Link to="https://github.com/mdx-js/mdx" target="_blank" mdxType="Link">{`MDX (mark down jsx)`}</Link>{` to write content which allows me to place react components within text! mark down has been my go to method for managing content for the past several years and now it's dynamic 😎. Previously I was able to use HTML within my markdown through transpiling on the fly but now with gatsby I'm able to generate each page as it's own application with built in page and bundle chunking.`}</p>
    <p>{`In the future I might checkout netlify's CMS or another service like contentful.`}</p>
    <h2>{`What else do I want to do?`}</h2>
    <ol>
      <li parentName="ol">{`Flesh out more components, starting with themed form elements`}</li>
      <li parentName="ol">{`Fork a popular library and inject my theme values and allow for easily created themable components to use in future projects.`}</li>
      <li parentName="ol">{`Write more content... I could go into depth on many patterns I've used on this site and other projects`}</li>
      <li parentName="ol">{`More experimentation, learning, and fun projects I can share`}</li>
    </ol>
    <hr></hr>
    <p>{`*`}{`It's great that I get to call researching and discovering new technology/frameworks "playing"`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      